export const loginFields = [
    {
        id: 'login',
        type: 'email',
        placeholder: 'Adres E-mail'
    },
    {
        id: 'password',
        type: 'password',
        placeholder: 'Hasło'
    },
];
