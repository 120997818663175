import { Splide } from "@splidejs/react-splide";
import { SplideSlide } from "@splidejs/react-splide";
import ReactPlayer from "react-player";
import "../../components/DynamicMediaElement/DynamicMediaElement.scss";

const options = {
  rewind: true,
  autoplay: true,
  width: 200,
  slide: "slide",
  type: "fade",
  arrows: false,
  perPage: 1,
  perMove: 1,
  interval: 3000,
  pagination: false,
  trimSpace: true,
};

export const renderVideoType = (links: string[]) => {
  const mediaElements = [] as any;
  links.forEach((link) => {
    if (link.includes(".jpg") || link.includes(".png")) {
      mediaElements.push(
        <img src={"https://api.pan-tablet.pl" + link} alt="No Work" />
      );
    } else if (link.includes("youtube")) {
      mediaElements.push(<ReactPlayer url={link} />);
    } else if (
      link.includes(".mp4") ||
      link.includes(".mkv") ||
      link.includes(".mov") ||
      link.includes(".avi") ||
      link.includes(".wmv") ||
      link.includes(".webm") ||
      link.includes(".flv")
    ) {
      mediaElements.push(
        <video src={"https://api.pan-tablet.pl" + link} controls />
      );
    }
  });

  return (
    <div className="img_viewer">
      <Splide options={options}>
        {mediaElements.map((mediaEl: any, index: number) => (
          <SplideSlide key={index}>{mediaEl}</SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
