export const addWorkFields = [
    {
        type: 'email',
        name: 'email',
        placeholder: 'Adres e-mail',
        id: 'email',
    },
    {
        type: 'text',
        name: 'phone',
        placeholder: 'Numer telefonu',
        id: 'phone',
    },
];