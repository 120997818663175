export const searchFieldsData = [
  {
    type: "text",
    id: "rating",
    placeholder: "Klasa",
  },
  {
    type: "text",
    id: "school",
    placeholder: "Szkoła",
  },
];
